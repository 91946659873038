<template>
  <div>
    <HeaderNavHomepage />

    <main class="main-wrapper">
      <div class="main-card">
        <section class="hero">
          <h1>
            <span class="nowrap">Ticketing for Inclusivity:</span> HelixPay’s
            Support of LGBTQIA+ <span class="nowrap">Events</span>
          </h1>
          <div class="post-date">October 10, 2024</div>
        </section>

        <div class="main">
          <div class="image-container">
            <vLazyImage src="/img/articles/lgbtq.jpg" />
          </div>
          <div class="photo-credits">
            Photo credits to @bausrofo @bekenemenprod on Instagram.
          </div>

          <p>
            At HelixPay, we are dedicated to fostering inclusivity and
            empowering safe spaces for all communities, especially the LGBTQIA+
            community. We’re proud to partner with creative platforms and events
            that celebrate queer artistry. Through our ticketing solutions,
            we’re helping create environments where everyone can feel safe,
            seen, and celebrated.
          </p>

          <p>
            One of our key partnerships is with
            <strong>Beke Nemen Podcast</strong>, a safe space for queer voices
            and creatives. By powering their drag viewing parties, we provide a
            welcoming environment for fans to enjoy and celebrate their favorite
            queens. These events are more than just entertainment—they bring the
            LGBTQIA+ community together in solidarity and self-expression. In
            addition, we have also partnered with
            <strong>Adonis Events and Talents</strong> for their drag viewing
            parties, further expanding our commitment to fostering inclusive,
            vibrant spaces for LGBTQIA+ culture.
          </p>

          <p>
            We also had the honor of being the ticketing partner for
            <strong>Brigiding</strong>, a Drag Race Philippines Season 1
            standout. Her Cake Adik Sayo event at Rampa Club on September 29 was
            a celebration of drag culture and individuality, providing a joyful,
            inclusive space for all.
          </p>

          <p>
            Our ticketing solutions also support large-scale celebrations like
            <strong>
              <a href="https://intensitymanila.helixpay.ph/"
                >Intensity Manila</a
              > </strong
            >, a thrilling circuit event happening on October 12 , and
            <strong><a href="https://kaput.helixpay.ph/">Kaput</a></strong
            >, an art and rave project celebrating queerness with its next event
            on October 19. These pivotal queer nightlife events blend artistic
            freedom, music, and rave culture, creating inclusive spaces for all
            attendees.
          </p>

          <p>
            At HelixPay, we believe empowering safe spaces is about creating
            platforms where communities can thrive, express themselves, and
            connect. By supporting events like Beke Nemen’s drag viewing
            parties, Brigiding’s performances, and queer celebrations like
            Intensity Manila and Kaput, we aim to foster spaces where everyone
            feels valued.
          </p>

          <p>
            Looking to create more inclusive and empowering spaces at your next
            event? Contact us at
            <a href="mailto:hello@helixpay.ph">hello@helixpay.ph</a> to discover
            how our solutions can help foster a welcoming environment where
            every attendee feels valued and connected.
          </p>
        </div>
      </div>
    </main>

    <MainFooter />
  </div>
</template>

<script setup>
import HeaderNavHomepage from '@/components/HeaderNavHomepage.vue';
import MainFooter from '@/components/MainFooter.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';
import vLazyImage from 'v-lazy-image';

const metaImagePath = 'img/articles/lgbtq.jpg';

const articlePath =
  '/articles/ticketing-for-inclusivity-helixpays-support-of-lgbtqia-events';

useHead(
  buildPageMeta({
    title: `Ticketing for Inclusivity: HelixPay’s Support of LGBTQIA+ Events`,
    description: `At HelixPay, we are dedicated to fostering inclusivity and empowering safe spaces for all communities, especially the LGBTQIA+ community.`,
    url: `https://www.helixpay.ph/${articlePath}`,
    image: `https://www.helixpay.ph/${metaImagePath}`,
  })
);
</script>

<style scoped>
.main-wrapper {
  background-image: url('/img/articles/safe-spaces-bg.png');
  padding: 2rem 0;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-color: #94c6bb;
}

.main {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.main p:first-child {
  margin-top: 0 !important;
}

.main-card {
  background: white;
  margin: 0 auto;
  max-width: 880px;
  border-radius: 1rem;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.12);
}

.hero {
  padding: 4rem 1.5rem;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.hero .content {
  position: relative;
  z-index: 9;
  text-shadow: rgba(0, 0, 0, 0.25) 1px 0 5px;
}

.hero h1 {
  margin: 0 auto;
  font-size: 1.15rem;
  letter-spacing: 0.05rem;
  line-height: 1.3;
  font-weight: 700;
  max-width: 1080px;
}

.image-container {
  padding-bottom: 100%;
  background: #b5c9c4;
  position: relative;
}

.image-container img {
  width: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
}

.main strong {
  font-weight: 600;
}

.main p,
.main h2 {
  margin: 2rem 1.5rem;
}

.main p {
  font-size: 0.9rem;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .main-wrapper {
    padding: 2rem 1.5rem;
  }
}

@media (min-width: 768px) {
  .hero {
    padding: 6rem 3rem;
  }

  .hero h1 {
    font-size: 1.75rem;
  }

  .main p,
  .main h2 {
    margin: 3rem auto;
    max-width: 512px;
  }

  .main p {
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  .hero h1 {
    font-size: 2rem;
  }

  .main p,
  .main h2 {
    max-width: 610px;
  }

  .main p {
    font-size: 1.15rem;
  }
}

.photo-credits {
  margin-top: 4px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
  opacity: 0.7;
}

.post-date {
  margin-top: 16px;
  font-size: 14px;
  opacity: 0.7;
}

strong {
  font-weight: 600;
}

a {
  color: #25a4e1;
}
</style>
